<template>
    <div>
        <el-card>
            <el-page-header :icon="null" title="病号管理">
                <template #content>
                    <span class="text-large font-600 mr-3"> {{ department }} </span>
                </template>
            </el-page-header>
            <el-switch
                v-model="WorkSwitch"
                inline-prompt
                size="large"
                active-text="上班"
                inactive-text="下班"
                @change="workSwitchChange()"
            />
        </el-card>


        <el-card style="margin-top: 20px;">


            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="姓名">
                <el-input v-model="formInline.user" placeholder="姓名" clearable />
                </el-form-item>
                <el-form-item label="身份证号">
                <el-input v-model="formInline.idcard" placeholder="身份证号" clearable />
                </el-form-item>
                <el-form-item label="状态">
                <el-select
                    v-model="formInline.state"
                    placeholder="未接诊"
                >
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    />
                </el-select>
                </el-form-item>
                <el-form-item label="登记日期">
                <el-date-picker
                    v-model="formInline.dateValue"
                    type="date"
                    placeholder=""
                    :disabled-date="disabledDate"
                />
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="handlePatientQuery()">查询</el-button>
                </el-form-item>
            </el-form>



        </el-card>

        <el-card style="margin-top: 20px;">

            <h3 style="margin-bottom: 20px;">挂号列表</h3>

            <el-table :data="alltableData()" border style="width: 100%">
                <el-table-column prop="_id" label="id" />
                <el-table-column prop="name" label="姓名" />
                <el-table-column prop="idCard" label="身份证号"/>
                <el-table-column prop="sex" label="性别"/>
                <el-table-column prop="age" label="年龄"/>
                <el-table-column prop="dateTime" label="日期" />
                <el-table-column prop="state" label="状态">
                    <template #default="scope">
                        <el-tag type="info" v-if="scope.row.state !== 1">已接诊</el-tag>
                        <el-tag type="primary" v-else>未接诊</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button v-if="scope.row.state === 1" size="small" @click="handleCall(scope.row)">
                            呼叫
                        </el-button>
                        <el-button v-else size="small" @click="handleEdit(scope.row,0)">
                            取消接诊
                        </el-button>
                        <el-button v-if="scope.row.state === 1" size="small" @click="handleEdit(scope.row,1)">
                            接诊完成
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        <div class="example-pagination-block">
            <el-pagination
                background
                layout="prev, pager, next ,total,sizes"
                :total="state.total"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
            />
        </div>
        </el-card>
    </div>
</template>
<script setup>
import { onMounted,ref,reactive } from 'vue';
import { useRoute } from 'vue-router'
import {useStore} from 'vuex'
import axios from 'axios';
import { ElMessage } from 'element-plus'
const store = useStore();
const dateValue = ref('')
const departmentData = ref()
const department = ref('')
const route = useRoute();
const formInline = reactive({
    dateValue:'',
    user:'',
    idcard:'',
    state:1
})
onMounted(()=>{
    getDepartment();
    formInline.dateValue = msToDate(new Date()).withoutTime
})
const WorkSwitch = ref()


const options = [
    {
        label:'未接诊',
        value:1
    },
    {
        label:'已接诊',
        value:0
    }
]

//登记病人列表
let tableData = ref([])
//获取上下班状态
const getDepartment = async ()=>{
    const res = await axios.get(`/adminApi/user/list/${store.state.userInfo._id}`)
    departmentData.value = res.data.data[0]
    department.value = departmentData.value.department[route.params.id]
    try {
        const workstates = axios.get(`/adminApi/workState/list/${store.state.userInfo._id}`);
        if ((await workstates).data.data[0].department === departmentData.value.department[route.params.id]) {
            WorkSwitch.value = true
        }else{
            WorkSwitch.value = false
        }
    } catch (error) {
        WorkSwitch.value = false
    }
    
    handlePatientQuery();

}


//获取登记病人信息
const getRegisteredPatientInformation =async ()=>{
    const registrationRes = await axios.get(`/adminApi/registration/getlist/${department.value}`)
    tableData.value = registrationRes.data.data
}


//修改上下班状态推送数据给门牌app
const workStateData = {department:department,id:store.state.userInfo._id}
const workSwitchChange = async ()=>{
    if(WorkSwitch.value){
        const res = await axios.post("/adminApi/workState/add",workStateData)
    }else{
        await axios.delete(`/adminApi/workState/del/${workStateData.id}`)
    }
}


//接诊按钮回调
const handleEdit =async (reg,num)=>{
    if (num) {
        reg.doctorId = store.state.userInfo._id
        reg.state = Number(!num)
        let res = await axios.put(`/adminApi/registration/putlist/${reg._id}`,reg)
        if (res.data.ActionType) {
            Socket.send('2');
        }
        // if (res.data.ActionType === 'ok') {
        //     ElMessage({
        //         message: '接诊完成！',
        //         type: 'success',
        //     })
        // }
    }else{
        let res = await axios.get(`/adminApi/registration/getdoctorId/${reg._id}`)
        if (res.data.data[0].doctorId === store.state.userInfo._id) {
            reg.state = Number(!num)
            let res = await axios.put(`/adminApi/registration/putlist/${reg._id}`,reg)
            if (res.data.ActionType) {
                Socket.send('2');
            }
            ElMessage({
                message: '取消成功！',
                type: 'success',
            })
        }else{
            ElMessage({
                showClose: true,
                message: '非本账号权限，无法取消',
                center: true,
            })
        }
    }

}

const Socket = new WebSocket('ws://47.93.246.213:8001');
Socket.onopen = ()=>{
    console.log('WebSocket已连接');
}
Socket.onmessage = function(evt){
//   console.log(evt.data);
  if (evt.data === '2') {
    handlePatientQuery();
  }
}

//分页表格用到的参数
const state = reactive({
    page: 1,
    limit: 10,
    total: tableData.value.length,
});
const alltableData = () => {
    state.total = tableData.value.length
    return tableData.value.filter(
        (item, index) =>
        index < state.page * state.limit &&
        index >= state.limit * (state.page - 1)
    );
};

 //改变页码
const handleCurrentChange = (e) => {
    state.page = e;
};
//改变页数限制
const handleSizeChange = (e) => {
    state.limit = e;
};



//呼叫按钮回调
const handleCall = (reg)=>{
    //将姓名及科室广播给前端
    Socket.send(reg.name +'|'+ department.value);
}



//日期转换
function msToDate (msec) {
    let datetime = new Date(msec);
    let year = datetime.getFullYear();
    let month = datetime.getMonth();
    let date = datetime.getDate();
    let hour = datetime.getHours();
    let minute = datetime.getMinutes();
    let second = datetime.getSeconds();
 
    let result1 = year + 
                 '-' + 
                 ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) + 
                 '-' + 
                 ((date + 1) < 10 ? '0' + date : date) + 
                 ' ' + 
                 ((hour + 1) < 10 ? '0' + hour : hour) +
                 ':' + 
                 ((minute + 1) < 10 ? '0' + minute : minute) + 
                 ':' + 
                 ((second + 1) < 10 ? '0' + second : second);
 
    let result2 = year + 
                 '-' + 
                 ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) + 
                 '-' + 
                 ((date + 1) < 10 ? '0' + date : date);
 
    let result = {
        hasTime: result1,
        withoutTime: result2
    };
 
    return result;
}

//日期选择
const disabledDate = (time) => {
  return time.getTime() > Date.now()
}

//列表查询回调
const handlePatientQuery =async ()=>{
    let res = {}
    if (msToDate(formInline.dateValue).withoutTime === '1970-01-01') {
        res = await axios.get(`/adminApi/registration/getlist/${department.value}`);
    }else{
        res = await axios.get(`/adminApi/registration/querylist/${department.value}/${msToDate(formInline.dateValue).withoutTime}`);
    }
    let resArr = []
    for (const key in res.data.data) {
        if (res.data.data[key].state === formInline.state) {
            resArr.push(res.data.data[key])
        }
    }
    
    if (formInline.user === '' && formInline.idcard === '') {
        tableData.value = resArr
    }else if (formInline.user === '' && formInline.idcard !== '') {
        resArr = []
        for (const key in res.data.data) {
            if (res.data.data[key].idCard === formInline.idcard) {
                resArr.push(res.data.data[key])
            }
        }
        tableData.value = resArr
    }else if (formInline.user !== '' && formInline.idcard === '') {
        resArr = []
        for (const key in res.data.data) {
            if (res.data.data[key].name === formInline.user) {
                resArr.push(res.data.data[key])
            }
        }
        tableData.value = resArr
    }else{
        resArr = []
        for (const key in res.data.data) {
            if (res.data.data[key].name === formInline.user && res.data.data[key].idCard === formInline.idcard) {
                resArr.push(res.data.data[key])
            }
        }
        tableData.value = resArr
    }
    
}
</script>
<style lang="scss" scoped>
.el-switch{
    margin-top: 10px;
}
.example-pagination-block{
    position: relative;
    margin-top:20px ;
    .el-pagination{
        justify-content: center;
    }
    
}
.demo-form-inline .el-input {
  --el-input-width: 220px;
}

.demo-form-inline .el-select {
  --el-select-width: 220px;
}
</style>